// extracted by mini-css-extract-plugin
export var imageeditorcanvasholder = "style-module--imageeditorcanvasholder--d233c";
export var imageeditorcanvasholderbody = "style-module--imageeditorcanvasholderbody--d4be6";
export var imageeditorcanvasholderfooter = "style-module--imageeditorcanvasholderfooter--01da4";
export var imageeditorcanvasholderheader = "style-module--imageeditorcanvasholderheader--24a15";
export var imageeditorcanvasholderheadertitle = "style-module--imageeditorcanvasholderheadertitle--32c4c";
export var imageeditorcontrolbox = "style-module--imageeditorcontrolbox--bef75";
export var imageeditorcontrolboxcancel = "style-module--imageeditorcontrolboxcancel--8219c";
export var imageeditorcontrolboxmessage = "style-module--imageeditorcontrolboxmessage--de71c";
export var imageeditordetailscell = "style-module--imageeditordetailscell--45560";
export var imageeditordetailsfull = "style-module--imageeditordetailsfull--cd8f5";
export var imageeditordetailsholder = "style-module--imageeditordetailsholder--9c2e7";
export var imageeditordetailslabel = "style-module--imageeditordetailslabel--75b7f";
export var imageeditordetailsthumbnail = "style-module--imageeditordetailsthumbnail--3962b";
export var imageeditordetailswide = "style-module--imageeditordetailswide--a689f";
export var imageeditorslider = "style-module--imageeditorslider--17fca";
export var imageeditorslidersize = "style-module--imageeditorslidersize--a9019";
export var imageeditorsliderx = "style-module--imageeditorsliderx--84f9e";
export var imageeditorslidery = "style-module--imageeditorslidery--acf04";