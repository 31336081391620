
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"


const searchFields = [
	{"label":"Name", "dbfield": "testtable_name", "type": "text", "filtertype": "textbox"},
	{"label":"Created", "dbfield": "testtable_created", "type": "datetime", "filtertype": "datetime"},
	{"label":"Updated", "dbfield": "testtable_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Active", "dbfield": "testtable_active", "type": "checkbox", "filtertype": "checkbox"},
	//{"label":"Image", "dbfield": "testtable_image", "type": "image", "filtertype": "image"},
	//{"label":"Banner", "dbfield": "testtable_banner", "type": "image", "filtertype": "image"},
	//{"label":"S3 Image", "dbfield": "testtable_file", "type": "image", "filtertype": "image"},
	//{"label":"File", "dbfield": "testtable_file", "type": "file", "filtertype": "file"},
];

const formFields = [
	[
		{"label":"Name", "field": "testtable_name", "value": "", "input": "textbox", "mode": "required"},
		{"label":"Created", "field": "testtable_created", "value": "", "input": "createtime", "mode": "readonly"},
		{"label":"Updated", "field": "testtable_updated", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Active", "field": "testtable_active", "value": "", "input": "checkbox", "mode": "normal"},
		{"label":"Image", "field": "testtable_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":2048, "imageheight":2048, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/tempimage/uploads"},
		{"label":"Banner", "field": "testtable_banner", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "private.cdn.ngnw.ph", "filelocation":"delme/testtable"},
		//{"label":"S3 Image", "field": "testtable_file", "value": "", "input": "image", "mode": "normal", "uploadtype": "webapp"},
		{"label":"File", "field": "testtable_file", "value": "", "input": "file", "mode": "normal", "uploadtype": "webapp"},
		{"label":"Blob", "field": "testtable_blob", "value": "", "input": "file", "mode": "normal"}
	]
];

const newformFields = [
];

const ENTITYID="testtable"

const TesttablePage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				location={location}
				allowadd={true}
				mobilerowtitlefield={["testtable_name"]}
				userSearchParam={{"orderby":"testtable_name"}}
				searchFields={searchFields}
				editFields={formFields}
				newformFields={formFields}
				pagetitle={pagetitle}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default TesttablePage;
