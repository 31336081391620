
import React from "react"

import OnsorderPage from "../../components/negosyonow/controls/onsordereditor";

const OverrideOrderPage = ({location}) => {
	return <OnsorderPage
				location={location}
				overridestatusrules={true}
				methodid={0}
			/>
}


export default OverrideOrderPage;
