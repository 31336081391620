
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

import PSGCSelector from "../../components/controls/psgcselector"

import * as styles from "../../components/webapps/style.module.css"

//const formatTools = require("../../../lib/formatTools")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="onsaddress"



/*
CREATE VIEW ONSADDRESS AS select

ONSLOCATIONUNIT.onslocationunit_id AS onsaddress_id,
ONSLOCATIONUNIT.onslocationunit_label AS onsaddress_label,
ONSLOCATIONUNIT.onslocationunit_floor AS onsaddress_floor,
ONSLOCATIONUNIT.onslocationunit_code AS onsaddress_unitcode,
concat(ONSLOCATIONUNIT.onslocationunit_floor,' ',ONSLOCATIONUNIT.onslocationunit_code) AS onsaddress_unit,
ONSLOCATION.onslocation_building AS onsaddress_building,
ONSLOCATION.onslocation_street AS onsaddress_street,
ONSLOCATION.onslocation_locality AS onsaddress_locality,
ONSLOCATION.onslocation_area AS onsaddress_area,
ONSLOCATION.onslocation_city AS onsaddress_city,
ONSLOCATION.onslocation_province AS onsaddress_province,
ONSLOCATION.onslocation_country AS onsaddress_country,
ONSLOCATION.onslocation_zipcode AS onsaddress_zipcode,
ONSLOCATIONUNIT.onslocationunit_notes AS onsaddress_notes,
ONSLOCATION.onslocation_latlong AS onsaddress_latlong,
ONSLOCATIONUNIT.onslocationunit_name AS onsaddress_unitname,
ONSLOCATION.onslocation_name AS onsaddress_lotname,
concat(ONSLOCATIONUNIT.onslocationunit_name,' ',ONSLOCATION.onslocation_name) AS onsaddress_name,
ONSLOCATION.onslocation_notes AS onsaddress_landmark,
ONSLOCATION.onslocation_restrictions AS onsaddress_restrictions,
ONSLOCATION.onslocation_locationcode AS onsaddress_locationcode,
ONSLOCATION.onslocation_id AS onslocation_id,
ONSLOCATIONUNIT.onslocationunittype_id

from (ONSLOCATION join ONSLOCATIONUNIT) where ONSLOCATION.onslocation_id = ONSLOCATIONUNIT.onslocation_id ;


*/
const searchFields = [
	{"label":"Office/Department", "dbfield": "onsaddress_label", "type": "text", "filtertype": "text"},
	{"label":"Floor/Unit", "dbfield": "onsaddress_unit", "type": "text", "filtertype": "text"},
	{"label":"Building/Street Address", "dbfield": "onsaddress_lotname", "type": "text", "filtertype": "text"},
	{"label":"City", "dbfield": "onsaddress_city", "type": "text", "filtertype": "text"},
	{"label":"Province", "dbfield": "onsaddress_province", "type": "text", "filtertype": "text"},
	{"label":"Notes", "dbfield": "onsaddress_notes", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Barangay", "field": "onsaddress_locality", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Municipality/City", "field": "onsaddress_city", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Province", "field": "onsaddress_province", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Country", "field": "onsaddress_country", "value": "Philippines", "input": "textbox", "mode": "normal"},

];


const newformFields = [
	{"label":"Province/City/Barangay", "field": "onsaddress_locality", "value": "", "input": "textbox", "mode": "normal", "info":"Province and City are required; Barangay is recommended to ensure deliveries will be processed"},
];


const commonFieldsHeader = [
	{"label":"Office/Department/Store", "field": "onsaddress_label", "value": "", "input": "textbox", "mode": "normal"},
	{"label":"Floor", "field": "onsaddress_floor", "value": "", "input": "textbox", "mode": "normal", "info":"e.g. G/F, 6, 12/F, B2, Basement 2, 5/M"},
	{"label":"Unit #", "field": "onsaddress_unitcode", "value": "", "input": "textbox", "mode": "normal", "info":"Office Room #, Aparment/Condo unit, etc"},
	{"label":"Type", "field": "onslocationunittype_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1, "display":"Residential"},
		{"value":2, "display":"Store"},
		{"value":3, "display":"Office"},
		{"value":4, "display":"Warehouse"},
	]},
	{"label":"Building/Compound Name", "field": "onsaddress_building", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Street Address", "field": "onsaddress_street", "value": "", "input": "textbox", "mode": "normal", "info":"Include street number, block, lot"},

	{"label":"Locality/Village/Subdivision", "field": "onsaddress_area", "value": "", "input": "textbox", "mode": "normal"},

];

const commonFieldsFooter = [

	{"label":"Zip Code", "field": "onsaddress_zipcode", "value": "", "input": "textbox", "mode": "normal"},

	{"label":"Landmark/Identifiers", "field": "onsaddress_landmark", "value": "", "input": "textbox", "mode": "required"},

	{"label":"Restrictions", "field": "onsaddress_restrictions", "value": "", "input": "textbox", "mode": "normal", "info":"e.g. for L300 size only"},

	{"label":"Location Notes/Instructions", "field": "onsaddress_notes", "value": "", "input": "textbox", "mode": "normal", "info":"Do not include order-specific details"},
];



const AddressManagerPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);
	const [psgcvalue, setPsgcvalue] = useState({"data":[]});

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		const onslocationunitmap = [
			//{"destination":"onslocationunit_name","source":"onsaddress_unitname"},
			//{"destination":"onslocationunit_id","source":"onsaddress_id"},
			{"destination":"onslocationunit_label","source":"onsaddress_label"},
			{"destination":"onslocationunit_floor","source":"onsaddress_floor"},
			{"destination":"onslocationunit_code","source":"onsaddress_unitcode"},
			{"destination":"onslocationunit_notes","source":"onsaddress_notes"},
			{"destination":"onslocationunittype_id","source":"onslocationunittype_id"}
		];
		const onslocationmap = [
			//{"destination":"onslocation_name","source":"onsaddress_lotname"},
			{"destination":"onslocation_building","source":"onsaddress_building"},
			{"destination":"onslocation_street","source":"onsaddress_street"},
			{"destination":"onslocation_area","source":"onsaddress_area"},
			//{"destination":"onslocation_locality","source":"onsaddress_locality"},
			//{"destination":"onslocation_city","source":"onsaddress_city"},
			//{"destination":"onslocation_province","source":"onsaddress_province"},
			//{"destination":"onslocation_country","source":"onsaddress_country"},
			{"destination":"onslocation_zipcode","source":"onsaddress_zipcode"},
			{"destination":"onslocation_latlong","source":"onsaddress_latlong"},
			{"destination":"onslocation_notes","source":"onsaddress_landmark"},
			{"destination":"onslocation_restrictions","source":"onsaddress_restrictions"},
			{"destination":"onslocation_locationcode","source":"onsaddress_locationcode"},
			//{"destination":"onslocation_id","source":"onslocation_id"}
		];

		var locationparams = remapparams(params, onslocationmap);
		var locationunitparams = remapparams(params, onslocationunitmap);

		// PSGC
		locationparams.onslocation_locality = psgcvalue.data.length > 2?psgcvalue.data[2].name:"";
		locationparams.onslocation_city = psgcvalue.data.length > 1?psgcvalue.data[1].name:"";
		locationparams.onslocation_province = psgcvalue.data.length > 0?psgcvalue.data[0].name:"";
		locationparams.onslocation_country = "Philippines";
		locationparams.onslocation_locationcode = psgcvalue.data.length > 0?psgcvalue.data[psgcvalue.data.length-1].code:"";

		// onslocation_name
		var tmpstr = "";
		var tmpdelim = "";
		var tmpidx = 0;
		const locationnamecombinelist = [
				"onslocation_building",
				"onslocation_street",
				"onslocation_area",
				"onslocation_locality",
				"onslocation_city",
				"onslocation_province",
				"onslocation_country",
				"onslocation_zipcode"
		];

		while (tmpidx < locationnamecombinelist.length) {
			if (locationparams[locationnamecombinelist[tmpidx]].length > 0) {
				tmpstr = tmpstr + tmpdelim + locationparams[locationnamecombinelist[tmpidx]].replace(/\s/g," ");
				tmpdelim = "\n";
			}
			tmpidx++;
		}
		locationparams.onslocation_name = tmpstr;

		tmpdelim = "";
		tmpstr = "";
		if (locationunitparams.onslocationunit_floor.length > 0) {
			tmpstr = fixFloorString(locationunitparams.onslocationunit_floor);
			locationunitparams.onslocationunit_floor = tmpstr;
			tmpdelim = "\n";
		}
		if (locationunitparams.onslocationunit_code.length > 0) {
			tmpstr = tmpstr + (tmpstr.length>0?" ":"")+ locationunitparams.onslocationunit_code.replace(/\s/g," ");
			tmpdelim = "\n";
		}

		if (locationunitparams.onslocationunit_label.length > 0) {
			tmpstr = locationunitparams.onslocationunit_label.replace(/\s/g," ") + tmpdelim+tmpstr;
		}
		locationunitparams.onslocationunit_name = tmpstr;

		//console.log(locationparams);
		//callback({"status":"Error", "message":JSON.stringify(params)}); return;

		getTablePKID("onslocation", [
				//"onslocation_country",
				"onslocation_area", "onslocation_locality", "onslocation_city",
				"onslocation_province", "onslocation_street", "onslocation_building"
			], locationparams, function(onslocation_id) {
				locationunitparams.onslocation_id = onslocation_id;
				//console.log(locationunitparams);

				getTablePKID("onslocationunit", [
						"onslocationunit_floor", "onslocationunit_code", "onslocationunit_label", "onslocation_id"
					], locationunitparams, function(onslocationunit_id) {
						//console.log("onsaddress_id",onslocationunit_id);
						if (onslocationunit_id>0) {
							callback({
								"status": "OK",
								"message": "Created",
								"documentid": onslocationunit_id
							});
						} else {
							callback({
								"status": "Error",
								"message": "Error creating data, please try again",
								"documentid": 0
							});
						}
					});
			});
	}


	function getTablePKID(tablename, searchfieldlist, dataobj, callback)
	{
		tablename = tablename.toLowerCase();
		var tmpidx = 0;
		var apiparam = {
			"dbfieldlist":[tablename+"_id"],
			"filters":[[]]
		};
		var dbfieldsuffixidx = 0;
		var dbfieldsuffix = "";
		while (tmpidx < searchfieldlist.length) {
			dbfieldsuffix = searchfieldlist[tmpidx];
			dbfieldsuffixidx = dbfieldsuffix.indexOf("_");
			if (dbfieldsuffixidx <= 0) {
				tmpidx++;
				continue;
			}
			dbfieldsuffix = dbfieldsuffix.substring(dbfieldsuffixidx);
			if (null !== dataobj[searchfieldlist[tmpidx]]) {
				if (dbfieldsuffix === "_id") {
					// Int, check if not null
					if (dataobj[searchfieldlist[tmpidx]] > 0) {
						apiparam.filters[0].push({
							field: searchfieldlist[tmpidx],
							operation: "=",
							value: dataobj[searchfieldlist[tmpidx]]
						});
					}
				} else {
					// String, check/compare even if empty string
					apiparam.filters[0].push({
						field: searchfieldlist[tmpidx],
						operation: "like",
						value: dataobj[searchfieldlist[tmpidx]]
					});
				}
			}
			tmpidx++;
		}
		if (apiparam.filters[0].length < 1) {
			apiparam.filters[0].push({
				field: tablename+"_name",
				operation: "like",
				value: ""
			});
		}

		webappsAPI.loadData(tablename, apiparam, token).then(dataresponse => {
			//console.log(dataresponse);
			if (dataresponse.hasOwnProperty("data")) {
				if (dataresponse.data.length > 0) {
					const foundobj = dataresponse.data[0];
					if (foundobj.hasOwnProperty(tablename+"_id")) {
						callback(foundobj[tablename+"_id"]);
						return;
					}
				}
			}

			//callback(0); return;
			webappsAPI.saveRecord(tablename, dataobj, token).then(response => {
				if (response.hasOwnProperty("documentid")) {
					callback(response.documentid);
				} else {
					callback(0);
				}
			}); // update main ticket

		}); // Load existing
	}

	function remapparams(params, maplist)
	{
		var tmpidx = 0;
		var tmpval = "";
		var output = {"subform":[],"deleterow":[]};
		var isnumeric = false;


		while (tmpidx < maplist.length) {
			isnumeric = false;
			if (maplist[tmpidx].source.substring(maplist[tmpidx].source.length - 3) === "_id") {
				isnumeric = true;
			}
			tmpval = "";
			if (!params.hasOwnProperty(maplist[tmpidx].source)) {
				tmpval = "";
			} else if (params[maplist[tmpidx].source] === null) {
				tmpval = "";
			} else {
				tmpval = params[maplist[tmpidx].source]+"";
			}
			if (tmpval.length < 1) {
				if (isnumeric){
					output[maplist[tmpidx].destination] = 0;
				} else {
					output[maplist[tmpidx].destination] = "";
				}
			} else {
				if (isnumeric) {
					output[maplist[tmpidx].destination] = parseInt(params[maplist[tmpidx].source], 10);
				} else {
					tmpval = tmpval.trim().replace(/\s/g, " ");
					while (tmpval.indexOf("  ")>=0) {
						tmpval = tmpval.replace(/\s\s/g," ");
					}
					output[maplist[tmpidx].destination] = tmpval;
				}
			}
			tmpidx = tmpidx+1;
		}
		return output;
	}


	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (documentid > 0) {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		return inputlist[idx];
	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (documentid === 0) {

			if (inputlist[idx].field === "onsaddress_locality") {
				const infotext = inputlist[idx].hasOwnProperty("info")?inputlist[idx].info:"";
				return <div className={styles.editfieldrow}>
						<label className={"font-size-regular "+styles.editfieldlabel}>
							{inputlist[idx].label}
						</label>
						<PSGCSelector
							// Use JSON/array field to prevent re-render
							handleInputChange={(newdata)=>{
								if (psgcvalue.data.length > 0) {
									if (psgcvalue.data[psgcvalue.data.length-1].code !== newdata[newdata.length-1].code) {
										inputchangecallback(newdata.length>=3?newdata[2].name:"", "text",fieldid);
									}
								}
								psgcvalue.data = newdata;
							}}
							disabled={isdisabled}
						/>
						{infotext.length > 0 && <div className={'noprint font-size-tiny '+styles.editfieldrow+" "+styles.editfieldrowinfo}>({infotext})</div>}
					</div>
			} else if (inputlist[idx].field === "onsaddress_city"
				|| inputlist[idx].field === "onsaddress_province"
				|| inputlist[idx].field === "onsaddress_country") {

				// Hide by default nnproduct_aliasreset
				return <></>;

			}
		}
		// default
		return null;
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		if (psgcvalue.data.length < 2) {
			return {"status":"Error", "message":"Please provide Province and City/Municipality (Barangay is recommended)"}
		}

		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "onsaddress_floor") {
				if (validateFloorString(mainform[idx].value) === false) {
					return {"status":"Error","message":"Invalid floor number"};
				}
			}
			idx++;
		}

		return {"status":"OK"};
	}

	function processFloorString(tmpstr)
	{
		// Clean up
		if (null === tmpstr) {
			return {valid:true, str:"", type:""};
		}
		tmpstr = tmpstr.replace(/\s/g,"").toUpperCase();
		if (tmpstr.length < 1) {
			return {valid:true, str:tmpstr, type:""};
		}

		// Type
		var outstr = "";
		var typechar = tmpstr.substring(0,1);

		if (typechar === "G" || typechar === "M") {
			// Basement not handled here because of possible #
			tmpstr = typechar;
		} else {
			tmpstr = tmpstr.replace("BASEMENT", "B");
			tmpstr = tmpstr.replace("FLOOR", "F");
			tmpstr = tmpstr.replace("TH", "");
			tmpstr = tmpstr.replace("1ST", "1");
			tmpstr = tmpstr.replace("2ND", "2");
			tmpstr = tmpstr.replace("3RD", "3");
			tmpstr = tmpstr.replace(/\//g, "");
			if (tmpstr.length < 1) {
				return {valid:true, str:tmpstr, type:""};
			}

			typechar = tmpstr.substring(tmpstr.length - 1);
		}


		if (isNaN(parseInt(typechar, 10))) {
			if (typechar === tmpstr) {
				// One Character data
				if (tmpstr === "G") {
					typechar = "F";
					tmpstr = "1";
				} else if (tmpstr === "B") {
					// Maps to Basement(no number)
					typechar = "F";
					tmpstr = "0";
				} else if (tmpstr === "M") {
					return {valid: true, str:"Mezzanine", type:typechar};
				}
			} else if (!["M","F"].includes(typechar)) {
				return {valid: false, str:tmpstr, type:""};
			}
		} else {
			typechar = "F";
		}

		// Number
		var floornum = 0;
		if (tmpstr.substring(0,1) === "B") {
			typechar = "B";
			floornum = parseInt(tmpstr.substring(1), 10);
		} else {
			floornum = parseInt(tmpstr, 10);
		}
		if (isNaN(floornum)) {
			return {valid: false, str:tmpstr, type:typechar};
		}

		const datalist = tmpstr.split(typechar);
		var checkindex = typechar === "B"?1:0;
		if (datalist.length > 2) {
			return {valid: false, str:tmpstr, type:typechar};
		} else if (datalist.length === 1) {
			checkindex = 0;
		}
		if (""+floornum !== datalist[checkindex]) {
			return {valid: false, str:tmpstr, type:typechar};
		} else if (floornum === 0) {
			// Basement with no number
			return {valid: true, str:"Basement", type:"B"};
		} else if (floornum < 0) {
			floornum = -1*floornum;
			if (typechar === "B") {
				typechar = "F";
			} else {
				typechar = "B";
			}
		}

		if (typechar === "B") {
			outstr = "B"+floornum;
		} else if (floornum === 1 && typechar === "F") {
			outstr = "G/F";
		} else {
			outstr = floornum+"/"+typechar;
		}

		return {valid: true, str:outstr, type:typechar};
	}

	function validateFloorString(tmpstr)
	{
		const outdata = processFloorString(tmpstr);
		//console.log("validateFloorString", tmpstr, outdata);
		return outdata.valid;
	}

	function fixFloorString(tmpstr)
	{
		const outdata = processFloorString(tmpstr);
		return outdata.str;
	}

	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		if (newformdata.hasOwnProperty(ENTITYID+"_id")) {
			if (newformdata[ENTITYID+"_id"]) {
				newdocid = newformdata[ENTITYID+"_id"];
			}
		}

		setDocumentid(newdocid);
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				location={location}
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={[]}
				searchFields={searchFields}
				editFields={[commonFieldsHeader.concat(formFields).concat(commonFieldsFooter)]}
				newformFields={[commonFieldsHeader.concat(newformFields).concat(commonFieldsFooter)]}

				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customSetFormData={customSetFormData}
				customFieldFragment={customFieldFragment}
				customValidateValues={formValidateValues}

				token={token} />
		</Layout>

}


export default AddressManagerPage;
