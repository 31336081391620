
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"


const ENTITYID="testtable"

const searchFields = [
	{"label":"Name", "dbfield": "testtable_name", "type": "text", "filtertype": "textbox"},
	{"label":"Created", "dbfield": "testtable_created", "type": "datetime", "filtertype": "datetime"},
	{"label":"Updated", "dbfield": "testtable_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Active", "dbfield": "testtable_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Fees", "dbfield": "testtable_addfees", "type": "currency", "filtertype": "currency"},
	{"label":"Total", "dbfield": "testtable_subtotal", "type": "currency", "filtertype": "currency"},
];

const formFields = [
	[
		{"label":"Name", "field": "testtable_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Created", "field": "testtable_created", "value": "", "input": "createtime", "mode": "readonly"},
		{"label":"Updated", "field": "testtable_updated", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Active", "field": "testtable_active", "value": "", "input": "checkbox", "mode": "normal"},
	]
];

const subformFields = [
	{
		"subformid": "item",
		"label": "Items",
		"table": "testsubtable",
		"sort": "testsubtable_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "testsubtable_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "QTY",
				"field": "testsubtable_qty",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Subtotal",
				"field": "testsubtable_qty*onsproduct_price*testsubtable_active",
				"subtotalid": "orderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "New QTY",
				"field": "testsubtable_newqty",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "New Subtotal",
				"field": "testsubtable_newqty*onsproduct_price*testsubtable_active",
				"subtotalid": "neworderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "testtable_addfees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Total Amount",
				"field": "testtable_addfees+neworderamount",
				"subtotalid": "",
				"destfield": "testtable_subtotal",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
		]
	}
];

const newformFields = [
];

const newSubformFields = [];

const SubtotalsTestPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappForm
				location={location}
				allowadd={true}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["testtable_name","testtable_created"]}
				userSearchParam={{"orderby":"testtable_created desc"}}
				searchFields={searchFields}
				editFields={formFields}
				newformFields={newformFields}
				editSubFormFields={subformFields}
				newSubformFields={newSubformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default SubtotalsTestPage;
