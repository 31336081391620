
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappWidgetTable from "../../components/webapps/widget/table";


const entity = "onscustomer";


const userparams = {
	//tablelist: [],
	joinlist: [{type:"left", table: "ONSORDERFORM", condlist: ["ONSCUSTOMER.onscustomer_id=ONSORDERFORM.onscustomer_id"]}],
	grouping: {fieldlist:["onscustomer_name"]},
	//condlist: []
}

const teststate = {"filter":[
	{"display":"Order date after '6/1/2024'","field":"onsorderform_datetime", "operation":" >= ","value":"2024-06-01"},
	{"display":"Order date before '7/1/2024'","field":"onsorderform_datetime", "operation":" < ","value":"2024-07-01"}
]};

const queryFieldsA = [
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Amount", "formula":"sum(onsorderform_totalamount)", "dbfield": "amount", "type": "currency", "filtertype": "currency"},
	{"label":"Transactions", "formula":"count(onsorderform_id)", "dbfield": "transaction", "type": "integer", "filtertype": "integer"},
];


const queryFieldsB = [
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"QTY", "formula":"sum(onsorderform_totalpcs)", "dbfield": "qty", "type": "currency", "filtertype": "integer"},
	{"label":"Transactions", "formula":"count(onsorderform_id)", "dbfield": "transaction", "type": "integer", "filtertype": "integer"},
];

const filterFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "filtertype": "date"},
];



const TempPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [message, setMessage] = useState([]);


	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<br/>
				<br/>
				<div style={{width:"40%", display:"inline-block", verticalAlign:"top"}}>
					<WebappWidgetTable
							title={"Order Amount"}
							token={token}
							entity={entity}
							fieldList={queryFieldsA}
							filterfieldList={filterFields}
							userparam={userparams}
							initsearchstate={teststate}
							showControls={false}
						/>
				</div>
				&nbsp;&nbsp;
				<div style={{width:"40%", display:"inline-block", verticalAlign:"top"}}>
					<WebappWidgetTable
							title={"Order PCs"}
							token={token}
							entity={entity}
							fieldList={queryFieldsB}
							filterfieldList={filterFields}
							userparam={userparams}
							initsearchstate={teststate}
						/>
				</div>
				<br/>
				<br/>
		</Layout>
}


export default TempPage;
