
import React, {useState} from "react"

import QRGenerator from "../../components/controls/qrgenerator";
import QRScanner from "../../components/controls/qrscanner";

import crc from "../../../lib/tools/crc"

const QRCRCTestPage = ({location}) => {
	const [scanmode, setScanmode] = useState(false);
	const [teststr, setTeststr] = useState("abc123");


	const scanHandler = function(data, success)
	{
		if (success) {
			setTeststr(data);
		}
		setScanmode(false);
	}

	return <div style={{textAlign:"center"}}>
		<br/><br/>
		<h1>QR/CRC Test Page</h1>
		<br/><br/>
		<div>
			<button onClick={(e)=>{setScanmode(true);}}>Scan QR</button><br/>
			<QRScanner scanning={scanmode} resultcallback={scanHandler} />
		</div>
		<br/><br/>
		<hr/>
		<br/><br/>
		<div>{teststr}</div>
		<div>{crc.generate(teststr).toString(16)}</div>
		<br/><br/>
		<div>
			<div style={{
				display:"inline-block",
				width: "10%"
			}}>
				<QRGenerator
					sizepx={150}
					data={"Hello World"}
				/>
			</div>
		</div>

	</div>
}

export default QRCRCTestPage;
