
import React from "react"

import FranchiseuserPage from "../../components/negosyonow/controls/franchiseeeditor"


const FranchiseapplicantPage = ({location}) => {
	return <FranchiseuserPage location={location} applicantpage={true} />
}


export default FranchiseapplicantPage;
