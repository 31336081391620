import React, {useRef, useState, useEffect, useCallback} from "react"
import PropTypes from "prop-types"

import ControlSelect from "../controls/select"
import FileBrowser from "../controls/filebrowser"

import * as styles from "./style.module.css"

import imgcheck from "../../images/buttons/done.png"
import imgclose from "../../images/buttons/close.png"

import imgdelete from "../../images/buttons/delete.png"
import imgmoveup from "../../images/buttons/expand_less.png"
import imgmovedown from "../../images/buttons/expand_more.png"


const IMGLAYER_MAXSTORESCALE=1;
const IMGLAYER_LIMIT=10;

const ImageEditor = ({disabled, identifier, targetWidth, targetHeight, className, uploadHandler}) => {
	const [updated, setUpdated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [imagelayers, setImagelayers] = useState([]);
	const [layerlist, setLayerlist] = useState([]);
	const [layeridx, setLayeridx] = useState(-1);
	const [editlayeridx, setEditlayeridx] = useState(-1);
	const [controlkey, setControlkey] = useState("");

	const canvasRef = useRef(null);
	const canvasRefTemp = useRef(null);

	const updateimage = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}
		setLoading(true);
		setMessage("Loading...");
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		context.beginPath();
		context.rect(0, 0, targetWidth, targetHeight);
		context.fillStyle = "#FFFFFF";
		context.fill();

		var curidx = 0;
		while (curidx < imagelayers.length) {
			context.drawImage(imagelayers[curidx].img, imagelayers[curidx].x, imagelayers[curidx].y, imagelayers[curidx].width, imagelayers[curidx].height);
			curidx++;
		}
		setLoading(false);
		setMessage("");
	}, [imagelayers, canvasRef, targetWidth, targetHeight])

	useEffect(()=>{
		updateimage(null);
	}, [imagelayers, updateimage])

	const clearFile = (event) => {
		setUpdated(false);
	}

	const uploadFile = (event) => {
		if (typeof uploadHandler !== 'undefined') {
			const canvas = canvasRef.current;
			const filetype = "image/jpeg";
			const outfname = "img.jpg";
			var buffer = canvas.toDataURL(filetype, 0.8);
			const bufferidx = buffer.indexOf(",");
			uploadHandler(identifier, outfname, buffer.substring(bufferidx+1), filetype);
			setUpdated(false);
			//clearFile(null);
		}

	}

	const addImageSrc = (srcdata, curname) => {
		var newdata = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			origwidth: 0,
			origheight: 0,
			data:srcdata
		}
		var img = new Image();
		img.onload = function() {
			var tmplayer = [...imagelayers];
			var scaleWidth = 0;
			var scaleHeight = 0;
			var scalefactorX = targetWidth/img.width;
			var scalefactorY = targetHeight/img.height;

			if (scalefactorY < scalefactorX) {
				scalefactorX = scalefactorY;
			} else {
				scalefactorY = scalefactorX;
			}

			if (scalefactorX < IMGLAYER_MAXSTORESCALE) {
				// Shrink image to store max size only
				const canvas = canvasRefTemp.current;
				const context = canvas.getContext('2d');

				scaleHeight = Math.floor(IMGLAYER_MAXSTORESCALE*scalefactorY*img.height);
				scaleWidth = Math.floor(IMGLAYER_MAXSTORESCALE*scalefactorX*img.width);

				context.canvas.height = scaleHeight;
				context.canvas.width = scaleWidth;
				context.clearRect(0, 0, scaleWidth, scaleHeight);
				context.drawImage(img, 0, 0, scaleWidth, scaleHeight);
				img.src = canvas.toDataURL("image/png");
				// Should trigger onload again
				return;
			}
			scaleHeight = img.height;
			scaleWidth = img.width;

			newdata.img = img;
			newdata.origwidth = img.width;
			newdata.origheight = img.height;
			newdata.width = scaleWidth;
			newdata.height = scaleHeight;
			newdata.x = Math.floor((targetWidth - scaleWidth)*0.5);
			newdata.y = Math.floor((targetHeight - scaleHeight)*0.5);

			tmplayer.push(newdata);
			setImagelayers(tmplayer);
			setEditlayeridx(layeridx+1);
			setLayeridx(layeridx+1);
			setControlkey(""+Date.now());

			tmplayer = [...layerlist];
			tmplayer.push({
				code:""+(layeridx+1),
				name:curname
			});
			setLayerlist(tmplayer);
			setLoading(false);
			setUpdated(true);
		}
		img.src = srcdata;
		setMessage("");
	}

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0 && layeridx+1 < IMGLAYER_LIMIT) {
			setLoading(true);
			setMessage("Loading...");

			let curname = event.target.files[0].name;
			const reader = new FileReader();
			reader.onload = readevent => {
				const buffer = readevent.target.result;
				const typestartidx = buffer.indexOf(":");
				const typeendidx = buffer.indexOf("/");
				const filetype = buffer.substring(typestartidx+1, typeendidx);

				if (filetype === "image") {
					addImageSrc(readevent.target.result, curname);
				} else {
					setLoading(false);
					setMessage("Invalid image file");
				}
			}
			reader.readAsDataURL(event.target.files[0]);
		}
	};


	const urlSelectedHandler = (url) => {
		if (layeridx+1 < IMGLAYER_LIMIT) {
			setLoading(true);
			setMessage("Loading...");

			const tmpidx = url.lastIndexOf("/");
			const curname = url.substring(tmpidx+1);
			addImageSrc(url, curname);
		}
	};

	const sliderSizeChange = (e)=>{
		if (e) {
			e.preventDefault();
		}
		var newval = -1*parseInt(e.target.value, 10);
		var tmplayer = [...imagelayers];
		var scaleval = newval/tmplayer[editlayeridx].origheight;
		const maxscaley = (targetHeight - tmplayer[editlayeridx].y)/tmplayer[editlayeridx].origheight;
		const maxscalex = (targetWidth - tmplayer[editlayeridx].x)/tmplayer[editlayeridx].origwidth;

		if (scaleval > maxscalex) {
			scaleval = maxscalex;
		}
		if (scaleval > maxscaley) {
			scaleval = maxscaley;
		}
		newval = Math.floor(scaleval*tmplayer[editlayeridx].origheight);
		if (newval !== tmplayer[editlayeridx].height) {
			tmplayer[editlayeridx].height = newval;
			tmplayer[editlayeridx].width = Math.floor(scaleval*tmplayer[editlayeridx].origwidth);
			setImagelayers(tmplayer);
		}
	};

	const sliderYChange = (e)=>{
		if (e) {
			e.preventDefault();
		}
		var newval = -1*parseInt(e.target.value, 10);
		var tmplayer = [...imagelayers];
		const maxval = targetHeight - tmplayer[editlayeridx].height;
		if (newval < 0) {
			newval = 0;
		} else if (newval >= maxval) {
			newval = maxval-1;
			if (newval < 0) {
				newval = 0;
			}
		}
		if (newval !== tmplayer[editlayeridx].y) {
			tmplayer[editlayeridx].y = newval;
			setImagelayers(tmplayer);
		}
	};

	const sliderXChange = (e)=>{
		if (e) {
			e.preventDefault();
		}
		var newval = parseInt(e.target.value, 10);
		var tmplayer = [...imagelayers];
		const maxval = targetWidth - tmplayer[editlayeridx].width;
		if (newval < 0) {
			newval = 0;
		} else if (newval >= maxval) {
			newval = maxval-1;
			if (newval < 0) {
				newval = 0;
			}
		}
		if (newval !== tmplayer[editlayeridx].x) {
			tmplayer[editlayeridx].x = newval;
			setImagelayers(tmplayer);
		}
	};

	const chooseLayer = (newlayerstr) =>{
		const tmpidx = parseInt(newlayerstr,10);
		if (tmpidx >= 0 && tmpidx < imagelayers.length) {
			setEditlayeridx(tmpidx);
		}
	};

	const deleteLayer = (e) => {
		if (e) {
			e.preventDefault();
		}
		var tmplayer = [];
		var tmplayerlist = [];
		var listidx = 0;
		var tmpidx = 0;
		var layercount = imagelayers.length;
		var newidx = editlayeridx;

		if (editlayeridx < 0) {
			return;
		} else if (editlayeridx >= layercount) {
			return;
		} else if (editlayeridx+1 === layercount) {
			// This should also handle deleting single entries
			newidx = editlayeridx-1;
		}
		setLoading(true);
		while (tmpidx < layercount) {
			if (tmpidx !== editlayeridx) {
				tmplayer.push(imagelayers[tmpidx]);
				// Don't copy code
				tmplayerlist.push({
					code: ""+listidx,
					name: layerlist[tmpidx].name
				});
				listidx++;
			}
			tmpidx++;
		}
		setLayerlist(tmplayerlist);
		setImagelayers(tmplayer);
		setEditlayeridx(newidx);
		setLayeridx(layeridx-1);
		if (layercount <= 1) {
			setUpdated(false);
		}
		setLoading(false);
	};

	const moveLayer = (srcidx, destidx) =>{
		var found = false;
		var tmplayer = [];
		var tmplayerlist = [];
		var tmpidx = 0;
		var idxa = srcidx;
		var idxb = destidx;

		if (srcidx > destidx) {
			idxa = destidx;
			idxb = srcidx;
		}

		if (idxa===idxb) {
			return;
		} else if (idxa < 0 || idxb < 0) {
			return;
		} else if (idxa >= imagelayers.length || idxb >= imagelayers.length) {
			return;
		}
		setLoading(true);
		while (tmpidx < imagelayers.length) {
			if (tmpidx === idxa) {
				// TODO: Assume consecutive for now
				tmplayer.push(imagelayers[tmpidx+1]);
				tmplayer.push(imagelayers[tmpidx]);

				// Don't copy code
				tmplayerlist.push({
					code: ""+tmpidx,
					name: layerlist[tmpidx+1].name
				});
				tmplayerlist.push({
					code: ""+(tmpidx+1),
					name: layerlist[tmpidx].name
				});

				tmpidx++;
				found=true;
			} else {
				tmplayerlist.push(layerlist[tmpidx]);
				tmplayer.push(imagelayers[tmpidx]);
			}
			tmpidx++;
		}
		if (found) {
			setLayerlist(tmplayerlist);
			setImagelayers(tmplayer);
			setEditlayeridx(destidx);
		}
		setLoading(false);
	};

	const moveLayerUp = (e) =>{
		if (e) {
			e.preventDefault();
		}
		moveLayer(editlayeridx, editlayeridx+1);
	};

	const moveLayerDown = (e) =>{
		if (e) {
			e.preventDefault();
		}
		moveLayer(editlayeridx, editlayeridx-1);
	};

	const resizeLayer = (e, type) =>{
		if (e) {
			e.preventDefault();
		}
		var tmplayer = [...imagelayers];
		var destX = 0;
		var destY = 0;
		var scaleWidth = imagelayers[editlayeridx].width;
		var scaleHeight = imagelayers[editlayeridx].height;
		var scalefactorX = 1;
		var scalefactorY = 1;

		if (type === "max") {
			scalefactorX = targetWidth/scaleWidth;
			scalefactorY = targetHeight/scaleHeight;

			if (scalefactorY < scalefactorX) {
				scalefactorX = scalefactorY;
			} else {
				scalefactorY = scalefactorX;
			}
			scaleHeight = Math.floor(scalefactorY*scaleHeight);
			scaleWidth = Math.floor(scalefactorX*scaleWidth);
			destX = Math.floor((targetWidth - scaleWidth)*0.5);
			destY = Math.floor((targetHeight - scaleHeight)*0.5);
		} else if (type.substring(0, 6) === "corner") {
			const factor = 0.35;
			const marginfactor = 0.10;
			var maxdim = Math.floor(targetWidth*factor);
			var margindim = 0;

			if (targetHeight < targetWidth) {
				maxdim = Math.floor(targetHeight*factor);
			}
			margindim = Math.floor(marginfactor*maxdim);

			scalefactorX = maxdim/scaleWidth;
			scalefactorY = maxdim/scaleHeight;
			if (scalefactorY < scalefactorX) {
				scalefactorX = scalefactorY;
			} else {
				scalefactorY = scalefactorX;
			}
			scaleHeight = Math.floor(scalefactorY*scaleHeight);
			scaleWidth = Math.floor(scalefactorX*scaleWidth);


			if (type === "cornerll") {
				destX = margindim;
				destY = targetHeight - margindim - scaleHeight;
			} else if (type === "cornerlr") {
				destX = targetWidth - margindim - scaleWidth;
				destY = targetHeight - margindim - scaleHeight;
			} else if (type === "cornerul") {
				destX = margindim;
				destY = margindim;
			} else if (type === "cornerur") {
				destX = targetWidth - margindim - scaleWidth;
				destY = margindim;
			} else {
				return;
			}

		} else {
			return;
		}

		tmplayer[editlayeridx].x = destX;
		tmplayer[editlayeridx].y = destY;
		tmplayer[editlayeridx].width = scaleWidth;
		tmplayer[editlayeridx].height = scaleHeight;



		setImagelayers(tmplayer);
	};


	return <div className={className}>
		<div className={"text-centered"}>
			<div className={styles.imageeditorcanvasholder}>
				{imagelayers[editlayeridx] ?
					<div className={styles.imageeditorcanvasholderheader}>
						<div>Y</div>
						<div className={styles.imageeditorcanvasholderheadertitle+" font-size-medium"}>{targetWidth} x {targetHeight}</div>
						<div>HT</div>
					</div>
				:
					<div className={styles.imageeditorcanvasholderheader}>
						<div className={styles.imageeditorcanvasholderheadertitle+" font-size-medium"}>{targetWidth} x {targetHeight}</div>
					</div>
				}
				<div className={styles.imageeditorcanvasholderbody}>
					{imagelayers[editlayeridx] &&
						<>
							<input
								disabled={loading || disabled}
								type="range"
								orient={"vertical"}
								min={-targetHeight}
								max={0}
								value={-1*imagelayers[editlayeridx].y}
								className={styles.imageeditorslider+" "+styles.imageeditorslidery}
								onChange={sliderYChange}
							/>
							<input
								disabled={loading || disabled}
								type="range"
								orient={"vertical"}
								min={-targetHeight}
								max={0}
								value={-1*imagelayers[editlayeridx].height}
								className={styles.imageeditorslider+" "+styles.imageeditorslidersize}
								onChange={sliderSizeChange}
							/>
						</>
					}
					<canvas style={{
						display:"inline-block"
					}} ref={canvasRef} width={targetWidth} height={targetHeight} />
					<canvas style={{display:"none"}} ref={canvasRefTemp} width={targetWidth} height={targetHeight} />
				</div>
				{imagelayers[editlayeridx] &&
					<div className={styles.imageeditorcanvasholderfooter}>
						<div>X</div>
						<input
							disabled={loading || disabled}
							type="range"
							min={0}
							max={targetWidth}
							value={imagelayers[editlayeridx].x}
							className={styles.imageeditorslider+" "+styles.imageeditorsliderx}
							onChange={sliderXChange}
						/>
						<div></div>
					</div>
				}
			</div>
			<div className={styles.imageeditordetailsholder}>
				<div>
					<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>Add:</span>
					<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailswide}>
						<FileBrowser
								disabled={loading || disabled}
								handleFile={fileSelectedHandler}
								textlabel={"Add image file"}
								className={"textbutton"}
								/>
						<br/>
						<button disabled={loading || disabled} onClick={(e)=>{urlSelectedHandler("https://cdn.ngnw.ph/asset/icon/limited_offers.png")}} className={"textbutton"}>
							<span>Limited Offers</span>
						</button>
					</span>
				</div>
				{imagelayers[editlayeridx] &&<>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>Layers:</span>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailswide}>
							<ControlSelect
								basekey={controlkey}
								disabled={loading || disabled}
								list={layerlist}
								defaultvalue={""+editlayeridx}
								handleChange={chooseLayer}
								/>

						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>
							Image:
						</span>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailswide}>
							<img className={styles.imageeditordetailsthumbnail} src={imagelayers[editlayeridx].data} alt=""/>
							<button onClick={deleteLayer} disabled={loading || disabled} className={"iconbutton"}  title={"Delete"}>
								<img alt={"Delete"} src={imgdelete} />
							</button>
						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>

						</span>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailswide}>
							<button onClick={moveLayerUp} disabled={loading || editlayeridx===layeridx || disabled} className={"iconbutton iconbutton-medium"}  title={"Up"}>
								<img alt={"Up"} src={imgmoveup} />
							</button>
							Move
							<button  onClick={moveLayerDown} disabled={loading || editlayeridx===0 || disabled} className={"iconbutton iconbutton-medium"}  title={"Down"}>
								<img alt={"Down"} src={imgmovedown} />
							</button>
						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>X:<br/>Y:</span>
						<span className={styles.imageeditordetailscell}>{imagelayers[editlayeridx].x}<br/>{imagelayers[editlayeridx].y}</span>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailslabel+" font-style-bold"}>WD:<br/>HT:</span>
						<span className={styles.imageeditordetailscell}>{imagelayers[editlayeridx].width}<br/>{imagelayers[editlayeridx].height}</span>
					</div>
					<div>
						<span className={"font-style-bold "+styles.imageeditordetailscell+" "+styles.imageeditordetailsfull}>
							Standard Size/Positions:
						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailsfull}>
							<button onClick={(e)=>{resizeLayer(e,"max")}} disabled={loading || disabled} className={"textbutton"}  title={"Max Size"}>
								Max. Size
							</button>
						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailsfull}>
							<button onClick={(e)=>{resizeLayer(e,"cornerul")}} disabled={loading || disabled} className={"textbutton"}  title={"Upper-Left"}>
								Upper-Left
							</button>
							<button onClick={(e)=>{resizeLayer(e,"cornerur")}} disabled={loading || disabled} className={"textbutton"} title={"Upper-Right"}>
								Upper-Right
							</button>
						</span>
					</div>
					<div>
						<span className={styles.imageeditordetailscell+" "+styles.imageeditordetailsfull}>
							<button onClick={(e)=>{resizeLayer(e,"cornerll")}} disabled={loading || disabled} className={"textbutton"} title={"Lower-Left"}>
								Lower-Left
							</button>
							<button onClick={(e)=>{resizeLayer(e,"cornerlr")}} disabled={loading || disabled} className={"textbutton"} title={"Lower-Right"}>
								Lower-Right
							</button>
						</span>
					</div>
				</>}
			</div>
		</div>

		<div className={"text-centered font-size-small"}>
			{loading ? <>Loading...</>
			:
				<>

				{message !== "" && <div className={styles.imageeditorcontrolboxmessage+" font-style-italic font-style-bold"}>{message}</div>}
				{(updated===true && disabled===false) &&
					<div  className={styles.imageeditorcontrolbox}>
						<button title={"Cancel"} disabled={true || loading || disabled} className={styles.imageeditorcontrolboxcancel+" iconbutton iconbutton-large"} onClick={clearFile} >
							<img src={imgclose} alt="Cancel"/>
						</button>
						<button title={"Accept"} disabled={true || loading || disabled} className={"iconbutton iconbutton-large"} onClick={uploadFile}>
							<img src={imgcheck} alt="Accept" />
						</button>
					</div>
				}
				</>
			}

		</div>
	</div>
}


ImageEditor.propTypes = {
	disabled: PropTypes.bool,
	identifier: PropTypes.string,
	className: PropTypes.string,
	targetWidth: PropTypes.number,
	targetHeight: PropTypes.number
};

ImageEditor.defaultProps = {
	disabled: false,
	identifier: "",
	className: "",
	targetWidth: 2048,
	targetHeight: 2048
};


export default ImageEditor