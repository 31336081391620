
const crcgen = function(data, polynomial, crc, xorout, mask)
{
	var i = 0;
	while (i < data.length) {
		crc ^= (data.charCodeAt(i)&0xff);
		// Loop unrolling 8x
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);
		crc = (crc&1) ? ((crc>>>1) ^ polynomial) : (crc >>> 1);

		i++;
	}
	var tmpoutput =  crc ^ xorout;
	if (tmpoutput < 0) {
		tmpoutput = (mask & (tmpoutput + 1))+mask;
	}
	return tmpoutput;
}

const crc16 = function(data) {
	// CRC16 ARC
	return crcgen(data, 0xA001, 0, 0, 0xffff);
}

const crc32 = function(data) {
	// CRC32
	return crcgen(data, 0xEDB88320, 0xffffffff, 0xffffffff, 0xffffffff);
}


const Utf8Encode = function(string) {
	string = string.replace(/\r\n/g,"\n");
	var utftext = "";

	for (var n = 0; n < string.length; n++) {
		var c = string.charCodeAt(n);
		if (c < 128) {
			utftext += String.fromCharCode(c);
		} else if((c > 127) && (c < 2048)) {
			utftext += String.fromCharCode((c >> 6) | 192);
			utftext += String.fromCharCode((c & 63) | 128);
		} else {
			utftext += String.fromCharCode((c >> 12) | 224);
			utftext += String.fromCharCode(((c >> 6) & 63) | 128);
			utftext += String.fromCharCode((c & 63) | 128);
		}
	}
	return utftext;
};


const generate = function(str, size = 16) {
	const utf8str = Utf8Encode(str);

	if (size === 16)
		return crc16(utf8str);
	return crc32(utf8str);
};

module.exports = {
	generate
}