
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"


const ENTITYID="testtable"

const searchFields = [
	{"label":"Name", "dbfield": "testtable_name", "type": "text", "filtertype": "textbox"},
	{"label":"Created", "dbfield": "testtable_created", "type": "datetime", "filtertype": "datetime"},
	{"label":"Updated", "dbfield": "testtable_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Active", "dbfield": "testtable_active", "type": "checkbox", "filtertype": "checkbox"},
];

const formFields = [
	[
		{"label":"Name", "field": "testtable_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Created", "field": "testtable_created", "value": "", "input": "createtime", "mode": "readonly"},
		{"label":"Updated", "field": "testtable_updated", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Scaled Image", "field": "testtable_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":2048, "imageheight":2048, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/tempimage/uploads/full"},
		{"label":"Active", "field": "testtable_active", "value": "", "input": "checkbox", "mode": "normal"},
	]
];

const subformFields = [
	{
		"subformid": "images",
		"label": "Images",
		"table": "testsubtable",
		"sort": "testsubtable_id",
		"mobilerowtitlefieldidx":[2],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "testsubtable_image",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"mode": "normal",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/tempimage/uploads/subimage/full", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageformat":"jpg"

			},
			{
				"label": "File",
				"field": "testsubtable_file",
				"subtotalid": "",
				"value": "",
				"input": "file",
				"mode": "normal",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filelocation": "negosyonow/test/subimage", // OPTIONAL: folder for file; no leading and tailing slashes
			},
			{
				"label": "Text File/JSON",
				"field": "testsubtable_textfile",
				"subtotalid": "",
				"value": "",
				"input": "textfile",
				"mode": "normal",
				"uploadtype": "webapp", // OPTIONAL: [https, webapp] Storage format, https=raw
				//"filelocation": "negosyonow/test/subimage", // OPTIONAL: folder for file; no leading and tailing slashes
			},
			{
				"label": "Notes",
				"field": "testsubtable_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
		]
	}
];

const newformFields = [
];

const newSubformFields = [];

const SubimagesTestPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappForm
				location={location}
				allowadd={true}
				allowdelete={true}
				pagetitle={pagetitle}
				mobilerowtitlefield={["testtable_name","testtable_created"]}
				userSearchParam={{"orderby":"testtable_created desc"}}
				searchFields={searchFields}
				editFields={formFields}
				newformFields={newformFields}
				editSubFormFields={subformFields}
				newSubformFields={newSubformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default SubimagesTestPage;
