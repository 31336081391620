import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types"

import imgselectarrow from '../../images/controls/select-arrow.png'

import * as styles from "./select.module.css"


const ControlSelect = ({basekey, list, defaultvalue, handleChange, textNone, disabled}) => {
	const [displayvalue, setDisplayvalue] = useState("");
	const [selectvalue, setSelectvalue] = useState(defaultvalue);

	const onValueChange = (event) => {
		if (event) {
			event.preventDefault();
		}
		if (event.target.value === selectvalue) {
			return;
		}
		let index = event.nativeEvent.target.selectedIndex;
		//console.log(event.target.value);
		setDisplayvalue(event.nativeEvent.target[index].text);
		setSelectvalue(event.target.value);
		if (typeof handleChange !== "undefined") {
			handleChange(event.target.value)
		}
	}
	useEffect(()=> {

		var idx = 0;
		while (idx < list.length) {
			if (defaultvalue === list[idx].code) {
				setDisplayvalue(list[idx].name);
				setSelectvalue(list[idx].code);
				break;
			}
			idx++;
		}
		if (idx >= list.length) {
			setSelectvalue("");
			setDisplayvalue("");
		}
	}, [defaultvalue, list])

	return(
		<div className={disabled?"selectbox "+styles.selectbox+" "+styles.selectboxdisabled:"selectbox "+styles.selectbox}>
			{disabled?
				<>&nbsp;{displayvalue}&nbsp;&nbsp;&nbsp;</>
			:
				<>
					<select key={basekey} value={selectvalue} className={styles.selectboxinput} onBlur={onValueChange} onChange={onValueChange}>
						{textNone !== "" &&
							<option key={"noneselected"} value={""}>{textNone}&nbsp;&nbsp;&nbsp;&nbsp;</option>
						}
						{list.map((data, index) => {
							var tmpkey = basekey+data.code;
							if (data.hasOwnProperty("key")) {
								tmpkey = basekey+data.key;
							}
							return <option key={tmpkey} value={data.code}>{data.name}&nbsp;&nbsp;&nbsp;&nbsp;</option>
						})}
					</select>
				</>
			}
			<img alt='' src={imgselectarrow} className={styles.selectboxicon}/>
		</div>
	)
}


ControlSelect.propTypes = {
	basekey: PropTypes.string,
	defaultvalue: PropTypes.string,
	textNone: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlSelect.defaultProps = {
	basekey: ``,
	defaultvalue: ``,
	textNone: ``,
	disabled: false,
}


export default ControlSelect;

