
import React, {useState} from "react"
import Layout from "../../components/layout"

import ImageEditor from "../../components/imageeditor";
//import ProductImage from "../components/negosyonow/controls/productimage";

const TempPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<div style={{
				}}>
					<ImageEditor
						/>

				</div>
		</Layout>
}


export default TempPage;
