
import React, {useState} from "react"
import Layout from "../../components/layout"


const negosyonowAPI = require ("../../../lib/requestNegosyownow");
const webappsAPI = require("../../../lib/requestWebapps");


const resultstyle = {
	border:"1px solid #ddd",
	margin:"10px",
	padding: "15px",
	textAlign: "center"

};

const TempPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [message, setMessage] = useState([]);


	function updateMessage(newstring)
	{
		setMessage(newstring.replace(/(?:\r\n|\r|\n)/g,"\n").split("\n"));
	}

	function testActivate(e)
	{
		if (e) {
			e.preventDefault();
		}
		var testdata = {
			docid: 760,
			entity: "nnproduct"
		}

		testdata = {
			docid: 1,
			entity: "nnseminar"
		}
		updateMessage("Loading...\n Please wait")
		negosyonowAPI.activateEntity(testdata.docid, token, testdata.entity).then(nnresponse => {
			updateMessage(JSON.stringify(nnresponse));
		});

	}

	function testQuery(e)
	{
		if (e) {
			e.preventDefault();
		}
		const primarytable = "onsorderform";
/*
		const params = {
				*tablelist: [],
				fieldlist: [],
				*joinlist: [{table:"", condlist:[], *type:""}],
				*filters:[[{field:"", value:"", *operation:""}]],
				*condlist: [],
				*grouping: {fieldlist:[], condlist:[]}
				*sortlist: [],

				*getallrows: false,
				*count: 20,
				*offset: 0
		}

*/
		const params = {
			//tablelist: [],
			fieldlist: ["count(onsorderform_id) as numtransaction", "sum(onsorderform_totalamount) as amount", "onscustomer_name"],
			joinlist: [{type:"left", table: "onscustomer", condlist: ["ONSCUSTOMER.onscustomer_id=ONSORDERFORM.onscustomer_id"]}],
			grouping: {fieldlist:["onscustomer_name"]},
			condlist: ["onsorderform_datetime like '2024-06-%'"]
		};

		updateMessage("Loading...\n Please wait")
		webappsAPI.queryData(primarytable,params,token).then(response => {
			updateMessage(JSON.stringify(response));
		});

	}


	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<div style={resultstyle} className="font-size-regular">
					{message.map((value, idx)=> {
						return <div>{value}</div>
					})}
				</div>
				<br/>
				<div className="text-centered">
					<button className={"textbutton textbutton-medium"} onClick={testQuery}>
						Query
					</button>

				</div>
				<br/>
				<br/>
		</Layout>
}


export default TempPage;
