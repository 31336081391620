
import React from "react"

import FranchiseuserPage from "../../components/negosyonow/controls/franchiseeeditor"


const FranchiseePage = ({location}) => {

	return <FranchiseuserPage location={location} applicantpage={false} />

}


export default FranchiseePage;
